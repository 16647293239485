import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, InputAdornment, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import { Add, Delete, Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import DataTable from '../../components/GqlList/DataTable';
import TableHeaders from '../../components/GqlList/TableHeaders';
import IconButtonMutation from '../../components/IconButtonMutation';

const headCells = [
  { field: 'title', headerName: 'Title' },
  { field: 'username', headerName: 'Client Id' },
  { field: 'password', headerName: 'Password' }
];

const SearchQuery = gql`
 query Search($s: String, $clientList: [uuid!]) {
    clients(where: {_and : {
      _or: [
        {
          title: {_ilike: $s}
        },
        {
          username: {_ilike: $s}
        }],
        _not : {
          id: {
            _in : $clientList
          }
        }
    }}) {
      id
      title
      username
    }
  }
`;

const AddClient = ({ onClick, clientList = [] }) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const { loading, data = {} } = useQuery(SearchQuery, {
    variables: { s: value ? `%${value}%` : '', clientList },
  });

  return (
    <div>
      <Tooltip title={t('Add')}>
        <IconButton aria-label="add" onClick={() => { setOpen(true); }}>
          <Add />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogContentText>
            {`${t('Add client')} :`}
          </DialogContentText>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={(event) => setValue(event.target.value)}
              placeholder={`${t('Search')}...`}
              value={value}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            {loading
              ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
              )
              : (
                <List disablePadding>
                  {data.clients.map((u, index) => (
                    <ListItem button onClick={() => onClick(u.id)} divider={index + 1 < data.clients.length}>
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {`${u.title} ${u.username}`}
                          </Typography>
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddClient.propTypes = {
  onClick: PropTypes.func,
  clientList: PropTypes.arrayOf(PropTypes.string)
};

const AddClientMutation = gql`
  mutation Add_client($clientId: uuid!, $projectId: Int!){
    insert_projects_clients_one(object : {client_id: $clientId, project_id: $projectId}) {
      id
      client_id
      project_id
    }
  }
`;

const ProjectLanguegeDeleteMutation = gql`
mutation Delete_clients($ids : [Int!]) {
  delete_projects_clients(where : {id : {_in : $ids}}){
    affected_rows
  }
}
`;

const DeleteClientFromProject = ({ ids = [], onCompleted }) => (
  <IconButtonMutation title="Delete" mutation={ProjectLanguegeDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
    <Delete />
  </IconButtonMutation>
);

DeleteClientFromProject.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const ProjectClients = ({ loading, data = {}, refetch }) => {
  const { project } = data;
  const [selectedIds, setSelected] = useState([]);
  const [mutate, { loading: loadingMutation }] = useMutation(AddClientMutation);
  const handelAddClient = (clientId) => {
    mutate({ variables: { clientId, projectId: project.id } }).then(() => refetch());
  };
  const clientList = project.projects_clients.map(({ client }) => client.id);

  return (
    <div>
      <TableHeaders
        selectedIds={selectedIds}
        // filtersList={filtersList}
        // filters={filters}
        // setFilters={setFilters}
        actions={[<AddClient onClick={handelAddClient} loading={loadingMutation} clientList={clientList} />]}
        setSelected={setSelected}
        onSelectActionComponent={[<DeleteClientFromProject />]}
        refetch={() => refetch()}
      />
      <DataTable
        // onRowClick={onRowClick}
        headCells={headCells}
        data={project.projects_clients.map(({ client, id }) => ({ clientId: client.id, ...client, id }))}
        selectedIds={selectedIds}
        setSelected={setSelected}
        checkboxSelection
        loading={loading}
      />
    </div>
  );
};

ProjectClients.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  refetch: PropTypes.func
};

export default ProjectClients;
