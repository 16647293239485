import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, set } from 'mout/object';
import { useQuery } from '@apollo/react-hooks';
import { LinearProgress } from '@material-ui/core';

// import { set } from 'mout/object';

import EnhancedTablePagination from './EnhancedTablePagination';
import TableHeaders from './TableHeaders';

import { usePagination, useSortBy, useSearch, useFilters } from './useVariable';

const defaultPagination = { page: 0, perPage: 5 };

const whereCompse = (filed, search) => {
  const sherble = {};
  set(sherble, `${filed}._ilike`, `%${search}%`);
  return sherble;
};

function List({ name, query, headCells = [], actions = [], onSelectActionComponent = [], onRowClick = Function.prototype, children }) {
  const [pagination, setPagination] = usePagination(name, defaultPagination);
  const [sort, setSort] = useSortBy(name);
  const [search, setSearch] = useSearch(name);
  const [filters, setFilters] = useFilters(name);
  const [selectedIds, setSelected] = useState([]);

  const orderBy = {};
  set(orderBy, sort.orderBy, sort.order);

  const searchableFields = headCells.filter((h) => h.searchable).map((h) => h.field);
  const where = search ? { _or: searchableFields.map((filed) => whereCompse(filed, search)) } : {};
  const filtersList = headCells.filter((c) => c.filter).map((c) => c.filter);

  const variables = {
    offset: parseInt(pagination.page * pagination.perPage, 10),
    limit: parseInt(pagination.perPage, 10),
    order_by: orderBy,
    where
  };

  const { loading, data = {}, refetch } = useQuery(query, { variables, fetchPolicy: 'network-only' });
  const checkboxSelection = onSelectActionComponent.length;

  return (
    <div>
      <TableHeaders
        search={search}
        onSearch={(value) => setSearch(value)}
        searchableFields={Boolean(searchableFields.length)}
        selectedIds={selectedIds}
        filtersList={filtersList}
        filters={filters}
        setFilters={setFilters}
        actions={actions}
        setSelected={setSelected}
        onSelectActionComponent={onSelectActionComponent}
        refetch={() => refetch(variables)}
      />
      {(loading) && <LinearProgress style={{ width: '100%' }} />}
      {React.cloneElement(React.Children.only(children),
        { data: data.list, onRowClick, headCells, setSort, sort, selectedIds, setSelected, checkboxSelection, loading })}
      <EnhancedTablePagination pagination={pagination} setPagination={setPagination} total={get(data, 'aggregate.aggregate.count') || 0} />
    </div>
  );
}

List.propTypes = {
  name: PropTypes.string,
  query: PropTypes.any,
  children: PropTypes.any,
  onRowClick: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.object),
  onSelectActionComponent: PropTypes.arrayOf(PropTypes.object),
  headCells: PropTypes.arrayOf(PropTypes.any)
};

export default List;
