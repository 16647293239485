import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Grid, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'mout/object';
import { useTranslation } from 'react-i18next';

const ProjectSchema = () => yup.object().shape({
  name: yup.string().max(128, 'Too Long!').required('First Name is Required'),
  source_language_id: yup.string().required('source languages is Required')
});

const defaultValues = {
  name: ''
};

const ProjectForm = ({ data = { project: {}, languages: [] }, mutate = Function.prototype, loading = false, disabled = false }) => {
  const initialValues = { ...defaultValues, ...omit(data.project, '__typename') };
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={ProjectSchema()}
      initialValues={initialValues}
      onSubmit={(_object) => {
        const object = { ..._object };
        mutate({ variables: { object, id: object.id } });
      }}
    >
      {({ values, handleSubmit, errors, touched, validateForm }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Field disabled={disabled} name="name" as={TextField} label={t('Name')} fullWidth margin="dense" variant="outlined" error={(errors.name && touched.name)} helperText={errors.name} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field disabled={disabled} name="source_language_id" as={TextField} select value={values.source_language_id || ''} error={(errors.source_language_id && touched.source_language_id)} helperText={errors.source_language_id} variant="outlined" label={t('Source language')} fullWidth margin="dense">
                <MenuItem selected disabled value="">
                  <em>None</em>
                </MenuItem>
                {data.languages.map(({ name: label, id }) => <MenuItem dense key={id} value={id}>{label}</MenuItem>)}
              </Field>
            </Grid>
          </Grid>
          {!disabled && (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: '20px' }}
              onClick={() => validateForm() && handleSubmit()}
              disabled={loading}
            >
              {t('Save details')}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

ProjectForm.propTypes = {
  disabled: PropTypes.bool,
  data: PropTypes.object,
  mutate: PropTypes.func,
  loading: PropTypes.bool,
};

export default ProjectForm;
