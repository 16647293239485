import React from 'react';

import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Mutation } from '@apollo/react-components';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  buttonRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgressCircular: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -18,
    marginLeft: -20,
  },
}));

const ButtonMutationIcon = ({ mutation, variables, title, children, onCompleted = Function.prototype }) => {
  const classes = useStyles();
  return (
    <Mutation mutation={mutation} onCompleted={onCompleted}>
      {(mutationFunction, { loading }) => (
        <div className={classes.buttonRoot}>
          <div className={classes.wrapper}>
            <Tooltip title={title} aria-label={title}>
              <IconButton disabled={loading} aria-label="delete" onClick={() => mutationFunction({ variables }).catch((err) => console.log(err))}>
                {children}
              </IconButton>
            </Tooltip>
            {loading && <CircularProgress className={classes.buttonProgressCircular} />}
          </div>
        </div>
      )}
    </Mutation>
  );
};

ButtonMutationIcon.propTypes = {
  mutation: PropTypes.any,
  variables: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.node,
  onCompleted: PropTypes.func
};

export default ButtonMutationIcon;
