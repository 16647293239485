import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, TextField, Tooltip, CircularProgress, makeStyles } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
// import { useNavigate } from 'react-router';
import { Mutation } from '@apollo/react-components';

import DataTable from '../../components/GqlList/DataTable';
import TableHeaders from '../../components/GqlList/TableHeaders';
import IconButtonMutation from '../../components/IconButtonMutation';
import DateField from '../../components/GqlList/fields/Date';

const headCells = [
  { field: 'text', headerName: 'Text' },
  { field: 'description', headerName: 'Description ' },
  { field: 'created_at', headerName: 'Created at', component: <DateField /> }
];

const useStyles = makeStyles(() => ({
  buttonRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgressCircular: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -18,
    marginLeft: -20,
  },
}));

const UploadSentenceMutation = gql`
  mutation Upload_sentences($sentences: [sentences_insert_input!]!) {
    insert_sentences(objects : $sentences){
      affected_rows
    }
  }
`;

const UploadSentence = ({ projectId, refetch }) => {
  const classes = useStyles();
  return (
    <Mutation mutation={UploadSentenceMutation} onCompleted={refetch}>
      {(uploadSentences, { loading }) => {
        const handleCapture = async ({ target }) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            try {
              const data = JSON.parse(reader.result);
              const sentences = Object.keys(data).filter((v) => v).map((text) => ({ text, project_id: projectId }));
              uploadSentences({ variables: { sentences } }).catch((err) => console.log(err));
            } catch (err) {
              console.log(err);
            }
          }, false);
          reader.readAsText(target.files[0]);
        };
        return (
          <div className={classes.buttonRoot}>
            <div className={classes.wrapper}>
              <Button
                component="label"
                color="primary"
                style={{ marginRight: '5px' }}
                variant="outlined"
                disabled={loading}
              >
                Upload
                <input
                  type="file"
                  accept=".json"
                  onChange={handleCapture}
                  style={{ display: 'none' }}
                />
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        );
      }}
    </Mutation>
  );
};

UploadSentence.propTypes = {
  refetch: PropTypes.func,
  projectId: PropTypes.number
};

const AddSentence = ({ onClick }) => {
  const [sentence, setSentence] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSentence(event.target.value);
  };

  return (
    <div>
      <Tooltip title={t('Add')}>
        <IconButton aria-label="add" onClick={() => { setOpen(true); }}>
          <Add />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogContentText>
            {`${t('Add sentence')} :`}
          </DialogContentText>
          <TextField fullWidth id="outlined-basic" label={t('Sentence')} variant="outlined" onChange={handleChange} />

        </DialogContent>
        <DialogActions>
          <Button disabled={!sentence} onClick={() => onClick(sentence, handleClose)} color="primary">
            {t('Ok')}
          </Button>
          <Button onClick={handleClose} color="secondary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddSentence.propTypes = {
  onClick: PropTypes.func
};

const AddSentenceMutation = gql`
  mutation Add_sentence($text: String!, $projectId: Int!){
    insert_sentences_one(object : {text: $text, project_id: $projectId}) {
      id
      text
    }
  }
`;

const ProjectLanguegeDeleteMutation = gql`
mutation Delete_sentences($ids : [uuid!]) {
  delete_sentences(where : {id : {_in : $ids}}){
    affected_rows
  }
}
`;

const DeleteSentences = ({ ids = [], onCompleted }) => (
  <IconButtonMutation title="Delete" mutation={ProjectLanguegeDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
    <Delete />
  </IconButtonMutation>
);

DeleteSentences.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const ProjectSentences = ({ loading, data = {}, refetch }) => {
  const { project } = data;
  const [selectedIds, setSelected] = useState([]);
  const [mutate, { loading: loadingMutation }] = useMutation(AddSentenceMutation);
  const handelAddLanguage = (text) => {
    mutate({ variables: { text, projectId: project.id } }).then(() => refetch());
  };

  return (
    <div>
      <TableHeaders
        selectedIds={selectedIds}
        // filtersList={filtersList}
        // filters={filters}
        // setFilters={setFilters}
        actions={[<UploadSentence projectId={project.id} refetch={refetch} />, <AddSentence onClick={handelAddLanguage} loading={loadingMutation} />]}
        setSelected={setSelected}
        onSelectActionComponent={[<DeleteSentences />]}
        refetch={() => refetch()}
      />
      <DataTable
        // onRowClick={onRowClick}
        headCells={headCells}
        data={project.sentences}
        selectedIds={selectedIds}
        setSelected={setSelected}
        checkboxSelection
        loading={loading}
      />
    </div>
  );
};

ProjectSentences.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  refetch: PropTypes.func
};

export default ProjectSentences;
