import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Paper, Input, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useDebounce from '../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const DebouncedSearchInput = (props) => {
  const { className, onChange, style, value = '', ...rest } = props;
  const [_value, setValue] = useState(value);
  const debouncedSearch = useDebounce(_value, 500);

  useEffect(
    () => {
      if (debouncedSearch != null && debouncedSearch !== value) {
        onChange(debouncedSearch);
      }
    },
    [debouncedSearch] // Only call effect if debounced search term changes
  );

  const classes = useStyles();
  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={style}
    >
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        value={_value}
        onChange={(event) => setValue(event.target.value)}
      />
    </Paper>
  );
};

DebouncedSearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default DebouncedSearchInput;
