import React from 'react';
import PropTypes from 'prop-types';
import ComparaisonFilter from './boleanFilter';

const FilterComponent = (props) => {
  const { type, ...rest } = props;
  return type === 'compare' ? <ComparaisonFilter {...rest} /> : null;
};

FilterComponent.propTypes = {
  type: PropTypes.string,
};

export default FilterComponent;
