import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';

function EnhancedTablePagination(props) {
  const { pagination, setPagination, total } = props;
  const handleChangeRowsPerPage = (event) => {
    setPagination({ page: 0, perPage: parseInt(event.target.value, 10) });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ page: newPage, perPage: pagination.perPage });
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 100]}
      component="div"
      count={total}
      rowsPerPage={parseInt(pagination.perPage, 10)}
      page={parseInt(pagination.page, 10)}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}

EnhancedTablePagination.propTypes = {
  pagination: PropTypes.exact({
    page: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    perPage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  }),
  setPagination: PropTypes.func,
  total: PropTypes.number
};

export default EnhancedTablePagination;
