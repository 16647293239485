import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import AppsIcon from '@material-ui/icons/Apps';

import BriefcaseIcon from '../../icons/Briefcase';

import useUserInfo from '../../hooks/useUserInfo';

// import useAuth from '../../hooks/useAuth';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from './NavSection';
import Scrollbar from '../Scrollbar';

const sections = [
  {
    title: 'Dashboard',
    path: '/app/dashboard',
    psermission: 100,
    icon: <ChartSquareBarIcon fontSize="small" />
  },
  {
    title: 'Users',
    path: '/app/users',
    psermission: 110,
    icon: <UsersIcon fontSize="small" />
  },
  {
    title: 'Projects',
    path: '/app/projects/',
    psermission: 100,
    icon: <BriefcaseIcon fontSize="small" />
  },
  {
    title: 'Clients',
    path: '/app/clients/',
    psermission: 125,
    icon: <AppsIcon fontSize="small" />
  }
];

const jobTitles = {
  100: 'translator',
  110: 'project manager',
  125: 'administrator'
};

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { t } = useTranslation();

  const { loading, user } = useUserInfo();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  if (loading || !user.id) {
    return null;
  }

  const permissionId = user.permission_id;
  const jobTitle = t(jobTitles[user.permission_id]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {`${user.first_name} ${user.last_name}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {jobTitle}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <NavSection
            pathname={location.pathname}
            sx={{
              '& + &': {
                mt: 3
              }
            }}
            title="General"
            items={sections.filter((s) => (permissionId >= s.psermission))}
          />
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
