import gql from 'graphql-tag';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Card } from '@material-ui/core';
import PropTypes from 'prop-types';

import GqlMutate from '../../components/GqlMutate';
import UserForm from './UserForm';
import Fetch from '../../components/GqlFetch';

const UserCreateMutation = gql`
  mutation insert_user($object : UsersInsertInput!) {
    insert_user(object : $object) {
      first_name
      last_name
      email
    }
  }
`;

const UserFetch = gql`
  query get_user($id : Int!) {
    user : users_by_pk(id: $id){
      id
      first_name
      last_name
      email
      permission_id
    }
    permissions {
      id
      level
    }
  }
`;

const UserDataFetch = gql`
  query get_user {
    permissions {
      id
      level
    }
  }
`;

const UserUpdateMutation = gql`
  mutation update_user($id : Int!, $object : users_set_input) {
    update_users_by_pk(pk_columns : {id : $id}, _set : $object) {
        id
        first_name
        last_name
        email
        permission_id
      }
    }
`;

const Loader = ({ loading, children, ...rest }) => (loading ? null : React.cloneElement(children, { loading, ...rest }));

Loader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node
};

const CreateUser = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <Box sx={{ p: 3 }}>
        <Fetch query={UserDataFetch}>
          <Loader>
            <GqlMutate mutation={UserCreateMutation} onCompleted={() => navigate('../')}>
              <Loader>
                <UserForm />
              </Loader>
            </GqlMutate>
          </Loader>
        </Fetch>
      </Box>
    </Card>
  );
};

const UpdateUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Card>
      <Box sx={{ p: 3 }}>
        <Fetch query={UserFetch} id={id}>
          <Loader>
            <GqlMutate mutation={UserUpdateMutation} onCompleted={() => navigate('../')}>
              <Loader>
                <UserForm />
              </Loader>
            </GqlMutate>
          </Loader>
        </Fetch>
      </Box>
    </Card>
  );
};

export {
  CreateUser,
  UpdateUser
};
