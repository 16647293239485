import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, InputAdornment, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import { Add, Delete, Search } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';

import DataTable from '../../components/GqlList/DataTable';
import TableHeaders from '../../components/GqlList/TableHeaders';
import IconButtonMutation from '../../components/IconButtonMutation';

const headCells = [
  { field: 'first_name', headerName: 'First Name' },
  { field: 'email', headerName: 'Email' },
  { field: 'owner', headerName: 'Owner' }
];

const SearchQuery = gql`
 query Search($s: String, $userList: [uuid!]) {
    users(where: {_and : {
      _or: [
        {
          first_name: {_ilike: $s}
        },
        {
          last_name: {_ilike: $s}
        },
        {
          email: {_ilike: $s}
        }],
        _not : {
          id: {
            _in : $userList
          }
        }
    }}) {
      id
      first_name
      last_name
      email
    }
  }
`;

const AddMember = ({ onClick, userList = [] }) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const { loading, data = {} } = useQuery(SearchQuery, {
    variables: { s: value ? `%${value}%` : '', userList },
  });

  return (
    <div>
      <Tooltip title={t('Add')}>
        <IconButton aria-label="add" onClick={() => { setOpen(true); }}>
          <Add />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DialogContentText>
            {`${t('Add user')} :`}
          </DialogContentText>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={(event) => setValue(event.target.value)}
              placeholder={`${t('Search')}...`}
              value={value}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            {loading
              ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
              )
              : (
                <List disablePadding>
                  {data.users.map((u, index) => (
                    <ListItem button onClick={() => onClick(u.id)} divider={index + 1 < data.users.length}>
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {`${u.first_name} ${u.last_name} || ${u.email}`}
                          </Typography>
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddMember.propTypes = {
  onClick: PropTypes.func,
  userList: PropTypes.arrayOf(PropTypes.string)
};

const AddMemberMutation = gql`
  mutation Add_member($userId: uuid!, $projectId: Int!){
    insert_projects_members_one(object : {user_id: $userId, project_id: $projectId}) {
      id
      user_id
    }
  }
`;

const ProjectLanguegeDeleteMutation = gql`
mutation Delete_members($ids : [Int!]) {
  delete_projects_members(where : {id : {_in : $ids}}){
    affected_rows
  }
}
`;

const DeleteUserFromProject = ({ ids = [], onCompleted }) => (
  <IconButtonMutation title="Delete" mutation={ProjectLanguegeDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
    <Delete />
  </IconButtonMutation>
);

DeleteUserFromProject.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const ProjectMembers = ({ loading, data = {}, refetch }) => {
  const { project } = data;
  const [selectedIds, setSelected] = useState([]);
  const [mutate, { loading: loadingMutation }] = useMutation(AddMemberMutation);
  const handelAddMember = (userId) => {
    mutate({ variables: { userId, projectId: project.id } }).then(() => refetch());
  };
  const userList = project.projects_members.map(({ user }) => user.id);

  return (
    <div>
      <TableHeaders
        selectedIds={selectedIds}
        // filtersList={filtersList}
        // filters={filters}
        // setFilters={setFilters}
        actions={[<AddMember onClick={handelAddMember} loading={loadingMutation} userList={userList} />]}
        setSelected={setSelected}
        onSelectActionComponent={[<DeleteUserFromProject />]}
        refetch={() => refetch()}
      />
      <DataTable
        // onRowClick={onRowClick}
        disabled={(row) => row.owner === 'Yes'}
        headCells={headCells}
        data={project.projects_members.map(({ user, id }) => ({ userId: user.id, ...user, id, owner: user.id === project.owner_id ? 'Yes' : 'No' }))}
        selectedIds={selectedIds}
        setSelected={setSelected}
        checkboxSelection
        loading={loading}
      />
    </div>
  );
};

ProjectMembers.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  refetch: PropTypes.func
};

export default ProjectMembers;
