import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moutGet from 'mout/object/get';
import React from 'react';
import unique from 'mout/array/unique';
import difference from 'mout/array/difference';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function DataTable({ sort = {}, setSort = Function.prototype, data = [], onRowClick = Function.prototype, selectedIds = [], setSelected = Function.prototype, headCells, checkboxSelection, disabled = (() => false) }) {
  const { order, orderBy } = sort;
  const { t } = useTranslation();

  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setSort({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };
  const classes = useStyles();

  const isSelected = (id) => selectedIds.indexOf(id) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = unique([...selectedIds, ...(data.filter((v) => !disabled(v)).map(({ id }) => id))]);
      setSelected(newSelecteds);
      return;
    }
    setSelected(difference(selectedIds, (data.filter((v) => !disabled(v)).map(({ id }) => id))));
  };

  const handleSelect = (id) => {
    const selectedIndex = selectedIds.indexOf(id);

    if (selectedIndex === -1) {
      setSelected([...selectedIds, id]);
    } else {
      setSelected([...selectedIds.slice(0, selectedIndex), ...selectedIds.slice(selectedIndex + 1)]);
    }
  };

  const checked = selectedIds.length && (data.filter(({ id }) => selectedIds.includes(id)).length === data.filter((v) => !disabled(v)).length);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {checkboxSelection ? (
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={checked} onChange={handleSelectAllClick} inputProps={{ 'aria-label': 'select all' }} />
              </TableCell>
            ) : null}
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.field}
                sortDirection={(orderBy && orderBy === headCell.field) ? order : false}
              >
                {(headCell.sortable === false) ? headCell.headerName : (
                  <TableSortLabel
                    active={(orderBy && orderBy === headCell.field)}
                    direction={(orderBy && orderBy === headCell.field) ? order : 'asc'}
                    onClick={() => createSortHandler(headCell.field)}
                  >
                    {t(headCell.headerName)}
                    {(orderBy && orderBy === headCell.field) ? (
                      <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                    ) : null}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {
            data.map((row, index) => (
              <TableRow key={row.id} onClick={() => onRowClick(row.id, row)} selected={isSelected(row.id)}>
                {checkboxSelection ? (
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isSelected(row.id)} inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }} onClick={(event) => { event.stopPropagation(); handleSelect(row.id); }} disabled={disabled ? disabled(row) : false} />
                  </TableCell>
                ) : null}
                {headCells.map((headCell) => (
                  <TableCell key={`${headCell.field} ${row.id}`}>
                    {headCell.component ? React.cloneElement(headCell.component, { record: row, source: headCell.field }) : moutGet(row, headCell.field)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DataTable.propTypes = {
  sort: PropTypes.exact({
    order: PropTypes.string,
    orderBy: PropTypes.string
  }),
  setSort: PropTypes.func,
  onRowClick: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  setSelected: PropTypes.func,
  headCells: PropTypes.any,
  disabled: PropTypes.func,
  checkboxSelection: PropTypes.bool,
  data: PropTypes.any
};

export default DataTable;
