import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Grid, Button, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'mout/object';
import { useTranslation } from 'react-i18next';

const UserSchema = () => yup.object().shape({
  first_name: yup.string().max(128, 'Too Long!').required('First Name is Required'),
  last_name: yup.string().max(128, 'Too Long!').required('Last Name is erquired'),
  email: yup.string().max(128, 'Too Long!').email('Invalid email').required('Email is Required'),
  permission_id: yup.string().required('permission is Required')
});

const defaultValues = {
  first_name: '',
  last_name: '',
  email: ''
};

const UserForm = ({ data = { user: {}, permissions: [] }, mutate = Function.prototype, loading = false }) => {
  const initialValues = { ...defaultValues, ...omit(data.user, '__typename') };
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={UserSchema()}
      initialValues={initialValues}
      onSubmit={(_object) => {
        const object = { ..._object };
        mutate({ variables: { object, id: object.id } });
      }}
    >
      {({ values, handleSubmit, errors, touched, validateForm }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Field name="first_name" as={TextField} label={t('First Name')} fullWidth margin="dense" variant="outlined" error={(errors.first_name && touched.first_name)} helperText={errors.first_name} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field name="last_name" as={TextField} label={t('Last Name')} fullWidth margin="dense" variant="outlined" error={(errors.last_name && touched.last_name)} helperText={errors.last_name} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field name="email" as={TextField} label={t('Email')} fullWidth margin="dense" variant="outlined" error={(errors.email && touched.email)} helperText={errors.email} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field name="permission_id" as={TextField} select value={values.permission_id || ''} error={(errors.permission_id && touched.permission_id)} helperText={errors.permission} variant="outlined" label={t('permission')} fullWidth margin="dense">
                <MenuItem value="" id="" />
                {data.permissions.map(({ level: label, id }) => <MenuItem dense key={id} value={id}>{t(label)}</MenuItem>)}
              </Field>
            </Grid>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '20px' }}
            onClick={() => validateForm() && handleSubmit()}
            disabled={loading}
          >
            {t('Save details')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  data: PropTypes.object,
  mutate: PropTypes.func,
  loading: PropTypes.bool,
};

export default UserForm;
