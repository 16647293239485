import React from 'react';
import { useQuery } from '@apollo/react-hooks';

const Fetch = ({ query, children, id, variables }) => {
  const _variables = id ? { id } : variables;

  const { loading, data, refetch } = useQuery(query, { variables: _variables, fetchPolicy: 'network-only' });

  return React.cloneElement(children, { data, loading, refetch: () => refetch(_variables) });
};

export default Fetch;
