/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { gql, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';

function ListItemLink(props) {
  const { primary, to, children } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{
            color: 'textPrimary',
            variant: 'subtitle2'
          }}
        />
        {children}
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node
};

const _results2 = [
  {
    title: 'Users',
    description: (d) => `${d.firstName} ${d.lastName} (${d.permission.level})`,
    link: () => {},
    date: (d) => d.createdAt && moment(d.createdAt).format('MMMM Do YYYY')
  },
  {
    title: 'Results',
    description: (d) => `${d.participant.firstName} ${d.participant.lastName} (session: ${d.session.title})`,
    date: (d) => d.startedAt && moment(d.startedAt).format('MMMM Do YYYY'),
    link: () => {},
  },
  {
    title: 'Session',
    description: (d) => `${d.title}`,
    date: (d) => d.startDate && moment(d.startDate).format('MMMM Do YYYY'),
    link: () => {},
  }
];

/* const QUERY = gql`
query Search($s: String) {
  users(where: {_or: [
    {
      firstName: {_ilike: $s}
    },
    {
      lastName: {_ilike: $s}
    },
    {
      email: {_ilike: $s}
    }
  ]
  }) {
    id
    program {
      id
      name
    }
    permission {
      id
      level
    }
    lastName
    firstName
    email
    createdAt
  },
  results(where : {
    _or: [
      {
        participant : {
          firstName: {_ilike: $s}
        }
      },
      {
        participant : {
          lastName: {_ilike: $s}
        }
      }
      {
        participant : {
          email: {_ilike: $s}
        }
      }
    ]
  }){
    id
    session {
      id
      title
    }
    participant {
      id
      firstName
      lastName
    }
    startedAt
  }
  sessions(where : {
    title: {
      _ilike : $s
    }
  }){
    id
    title
    startDate
  }
}`;

/*
const SearchResults = ({ results }) => (
  <>
    {results.map((result, i) => (
      <Box
        key={i}
        sx={{ mb: 2 }}
      >
        <Link
          color="textPrimary"
          component={RouterLink}
          to="/dashboard"
          variant="h5"
        >
          {result.title}
        </Link>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          {result.description}
        </Typography>
      </Box>
    ))}
  </>
);
*/

const SearchResults = ({ results }) => (
  <>
    {results.map(({ title, data = [], description, date }) => Boolean(data.length) && (
      <Box
        key={title}
        // sx={{ mb: 2 }}
      >
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {title}
        </Typography>
        <Divider />
        <List disablePadding>
          {data.map((d) => (
            <ListItemLink
              primary={
                description(d)
              }
              to="/"
            >
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {date(d)}
              </Typography>
            </ListItemLink>
          ))}
        </List>
      </Box>
    ))}
  </>
);
SearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.any)
};

const ContentSearch = () => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  // const [showResults, setShowResults] = useState(false);
  /* const { loading, data = {} } = useQuery(QUERY, {
    variables: { s: value ? `%${value}%` : '' },
  });

  _results2[0].data = (data.users || []).slice(0, 4);
  _results2[1].data = (data.results || []).slice(0, 4);
  _results2[2].data = (data.sessions || []).slice(0, 4);
  _results2[0].data = _results2[0].data.slice(0, 4);
  _results2[1].data = _results2[1].data.slice(0, 4);
  _results2[2].data = _results2[2].data.slice(0, 4);
  */
  const loading = false;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('Search')}>
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%' }
        }}
        variant="temporary"
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3 }}>
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                placeholder={`${t('Search')}...`}
                value={value}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              {loading
                ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )
                : (
                  <>
                    <SearchResults results={_results2} />
                  </>
                )}
            </Box>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default ContentSearch;
