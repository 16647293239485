import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CircularProgress2 from '../../CircularProgress';

const ProgressTranslation = ({ record, source, label }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'flex-end'
      }}
    >
      <Box sx={{ mr: 2 }}>
        <Typography
          color="textPrimary"
          variant="subtitle2"
        >
          {parseInt(record[source] * 100, 10)}
          %
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {t(label)}
        </Typography>
      </Box>
      <CircularProgress2 value={parseInt(record[source] * 100, 10)} />
    </Box>
  );
};

ProgressTranslation.propTypes = {
  record: PropTypes.any,
  label: PropTypes.string,
  source: PropTypes.string
};

export default ProgressTranslation;
