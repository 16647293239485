import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { get } from 'mout/object';

// import useAuth from '../hooks/useAuth';

import { getAuth } from '../contexts/AuthContext';

// const doneParser = ({ operation, result }) => console.log({ operation, result });

const doneParser = ({ operation }) => {
  const { operationName } = operation;
  return `${operationName.split('_').join(' ')} done.`;
};

const errorParser = ({ message, extensions = {} }) => {
  const { code, path, internal = {} } = extensions;
  const internalMessage = get(internal, 'error.message');
  console.log({ code, path });
  if (internalMessage) {
    return internalMessage;
  }
  return message;
};

const getRoles = (roles = []) => roles.filter((v) => (['administrator', 'user', 'project manager', 'translator'].includes(v)));

const ApolloProviderApp = ({ children }) => {
  /* const { token, roles } = useAuth();

  const headers = {
    authorization: `Bearer ${token}`,
    'x-hasura-role': (getRoles(roles) || [])[0]
  }; */

  const { enqueueSnackbar } = useSnackbar();

  const loggerLink = new ApolloLink((operation, forward) => forward(operation).map((result) => {
    const operationType = operation.query.definitions[0].operation;
    if (operationType === 'mutation' && !result.errors) {
      enqueueSnackbar(doneParser({ operation, result }), { variant: 'success' });
    }
    return result;
  }));

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    console.log({ graphQLErrors, networkError });
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => enqueueSnackbar(errorParser(error), { variant: 'error' }));
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // history.push('/not-found/') // redirect to network-error route
    }
  });

  const httpLink = new HttpLink({ uri: window._env_.APP_HASURA_URL });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
      const { roles, token } = getAuth();
      return ({
        headers: {
          ...headers,
          'x-hasura-role': (getRoles(roles) || [])[0],
          authorization: `Bearer ${token}`,
        }
      });
    });

    return forward(operation);
  });

  const link = ApolloLink.from([authMiddleware, loggerLink, errorLink, httpLink]);
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
  });
  return (<ApolloProvider client={client}>{children}</ApolloProvider>);
};

ApolloProviderApp.propTypes = {
  children: PropTypes.node.isRequired
};

export default ApolloProviderApp;
