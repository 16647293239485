import gql from 'graphql-tag';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box, CardActions, Button, Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useTranslation } from 'react-i18next';

import CardsTable from '../../components/GqlList/CardsTable';
import List from '../../components/GqlList/GqlList';

const ProjectsListQuery = gql`
  query Projects($offset: Int, $limit: Int, $order_by: [projects_order_by!], $where: projects_bool_exp) {
    list : projects(
      offset: $offset, 
      limit: $limit, 
      order_by: $order_by, 
      where: $where
    ) {
      id
      name
      language {
        code
        id
        name
      }
      project_language_progress {
        language {
          name
        }
        count
      }
      sentences_aggregate {
        aggregate {
          count
        }
      }
    }
    aggregate : projects_aggregate( where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  cards: {
    margin: '10px',
    minWidth: '250px',
    maxWidth: '350px',
    width: '100%'
  },
  content: {
    height: '120px',
    marginBottom: '4px'
  },
  actions: {
    marginTop: '4px'
  }
}));

const CartProject = ({ record }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onRowClick = () => navigate(`../projects/update/${record.id}`);
  const { t } = useTranslation();

  return (
    <Card className={classes.cards} variant="outlined">
      <CardHeader
        title={(
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h5">{`${record.name}`}</Typography>
            <Typography variant="h6" color="textSecondary">
              {record.language.code}
            </Typography>
          </Box>
        )}
      />
      <Divider />

      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {t('Total sentences')}
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {record.sentences_aggregate.aggregate.count}
          </Typography>
        </Box>
        {
          record.project_language_progress.map((l) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t(l.language.name)}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {l.count}
              </Typography>
            </Box>
          ))
        }

      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => onRowClick()}
        >
          {t('View Project')}
          <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

CartProject.propTypes = {
  record: PropTypes.any
};

const ProjectsList = () => (
  <>
    <List name="projectsList" query={ProjectsListQuery}>
      <CardsTable>
        <CartProject />
      </CardsTable>
    </List>
  </>
);

export default ProjectsList;
