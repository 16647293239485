import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

const DateField = ({ record, source, format = 'MMMM Do YYYY' }) => (
  <Typography
    component="span"
    variant="body2"
  >
    {moment(record[source]).format(format)}
  </Typography>
);

DateField.propTypes = {
  record: PropTypes.any,
  format: PropTypes.string,
  source: PropTypes.string
};

export default DateField;
