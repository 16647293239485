import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import qs from 'qs';
import get from 'mout/object/get';

function useVariable(name) {
  const location = useLocation();
  const navigate = useNavigate();
  const q = location.search.replace('?', '');

  const setVariable = useCallback((_variables) => {
    const decodedQ = qs.parse(q);
    decodedQ[name] = _variables;
    navigate({ pathname: location.pathname, search: `?${qs.stringify(decodedQ)}` });
  }, [q]);
  const variables = useMemo(() => qs.parse(q)[name], [q]);
  return [variables, setVariable];
}

function usePagination(name, defaultValue = {}) {
  const [variables, setVariable] = useVariable(name) || {};
  const page = get(variables, 'pagination.page') || defaultValue.page;
  const perPage = get(variables, 'pagination.perPage') || defaultValue.perPage;
  const pagination = useMemo(() => ({ page, perPage }), [page, perPage]);
  const setPagination = useCallback((value) => {
    setVariable({ ...variables, pagination: value });
  }, [JSON.stringify(variables)]);
  return [pagination, setPagination];
}

function useSortBy(name, defaultValue = {}) {
  const [variables, setVariable] = useVariable(name) || {};
  const order = get(variables, 'sort.order') || defaultValue.order;
  const orderBy = get(variables, 'sort.orderBy') || defaultValue.orderBy;
  const sort = useMemo(() => ({ order, orderBy }), [order, orderBy]);
  const setSort = useCallback((value) => {
    setVariable({ ...variables, sort: value });
  }, [JSON.stringify(variables)]);
  return [sort, setSort];
}

function useSearch(name) {
  const [variables = {}, setVariable] = useVariable(name);
  const setSearch = useCallback((value) => {
    setVariable({ ...variables, search: value });
  }, [JSON.stringify(variables)]);
  return [variables.search, setSearch];
}

function useFilter(name, filterName, defaultValue = {}) {
  const [variables, setVariable] = useVariable(name) || {};
  const filter = get(variables, `filters.${filterName}`) || defaultValue;
  const setFilter = useCallback((value) => {
    const filters = { ...(variables.filters || {}), [filterName]: value };
    setVariable({ ...variables, filters });
  }, [JSON.stringify(variables)]);
  return [filter, setFilter];
}

function useFilters(name) {
  const [variables, setVariable] = useVariable(name) || {};
  const filters = get(variables, 'filters');
  const setFilters = useCallback((_filters) => {
    setVariable({ ...variables, filters: _filters });
  }, [JSON.stringify(variables)]);
  return [filters, setFilters];
}

export { useVariable, usePagination, useSortBy, useSearch, useFilter, useFilters };
