import { Tooltip, IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import DataTable from 'src/components/GqlList/DataTable';
import useUserInfo from 'src/hooks/useUserInfo';

import List from '../../components/GqlList/GqlList';
import IconButtonMutation from '../../components/IconButtonMutation';

const headCells = [
  { field: 'name', headerName: 'Name', order: 'name', searchable: true },
  { field: 'language.name', headerName: 'Source language', order: 'language.name' },
];

const ProjectsListQuery = gql`
  query Projects($offset: Int, $limit: Int, $order_by: [projects_order_by!], $where: projects_bool_exp) {
    list : projects(
      offset: $offset, 
      limit: $limit, 
      order_by: $order_by, 
      where: $where
    ) {
      id
      name
      language {
        id
        name
      }
    }
    aggregate : projects_aggregate( where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const ProjectsDeleteMutation = gql`
  mutation delete_projects($ids : [Int!]) {
    delete_projects(where : {id: {_in : $ids}}) {
      affected_rows
    }
  }`;

const DeleteProjects = ({ ids = [], onCompleted }) => {
  const { t } = useTranslation();
  return (
    <IconButtonMutation title={t('Delete')} mutation={ProjectsDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
      <Delete />
    </IconButtonMutation>
  );
};

DeleteProjects.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const AddProject = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Add')}>
      <IconButton aria-label="add" onClick={() => navigate('./create')}>
        <Add />
      </IconButton>
    </Tooltip>
  );
};

const ProjectsList = () => {
  const navigate = useNavigate();
  const { user } = useUserInfo();
  const actions = user.permission_id > 100 ? [<AddProject />] : [];
  const onSelectActionComponent = user.permission_id > 100 ? [<DeleteProjects />] : [];

  return (
    <>
      <List name="projectsList" onRowClick={(id) => navigate(`./update/${id}`)} headCells={headCells} query={ProjectsListQuery} actions={actions} onSelectActionComponent={onSelectActionComponent}>
        <DataTable />
      </List>
    </>
  );
};

export default ProjectsList;
