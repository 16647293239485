import 'react-perfect-scrollbar/dist/css/styles.css';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import GlobalStyles from './components/GlobalStyles';
import routes from './Routes';
import useSettings from './hooks/useSettings';
import { createCustomTheme } from './theme';
import RTL from './components/RTL';

import './i18n';
import 'moment/locale/fr';
import 'moment/locale/ar-tn';

const App = () => {
  const routing = useRoutes(routes);
  const { settings } = useSettings();
  const { i18n } = useTranslation();
  const direction = settings.lng === 'tn' ? 'rtl' : 'ltr';

  const theme = createCustomTheme({
    direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    borderRadius: settings.borderRadius,
    theme: settings.theme,
    lng: settings.lng
  });

  useEffect(() => {
    i18n.changeLanguage(settings.lng);
    moment.locale(settings.lng === 'tn' ? 'ar-tn' : settings.lng);
  }, [settings.lng]);

  return (
    <RTL direction={direction}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </RTL>
  );
};

export default App;
