// import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
// import unique from 'mout/array/unique';
// import difference from 'mout/array/difference';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';

/*  const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
*/
function DataTable({ data = [], children/* , sort = {}, setSort = Function.prototype, onRowClick = Function.prototype, selectedIds = [], setSelected = Function.prototype, headCells, checkboxSelection, disabled = (() => false) */ }) {
  // const { t } = useTranslation();

  /*
  const { order, orderBy } = sort;
  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setSort({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };
  */
  /*
  const isSelected = (id) => selectedIds.indexOf(id) !== -1;
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = unique([...selectedIds, ...(data.filter((v) => !disabled(v)).map(({ id }) => id))]);
      setSelected(newSelecteds);
      return;
    }
    setSelected(difference(selectedIds, (data.filter((v) => !disabled(v)).map(({ id }) => id))));
  };

  const handleSelect = (id) => {
    const selectedIndex = selectedIds.indexOf(id);

    if (selectedIndex === -1) {
      setSelected([...selectedIds, id]);
    } else {
      setSelected([...selectedIds.slice(0, selectedIndex), ...selectedIds.slice(selectedIndex + 1)]);
    }
  };
  const checked = selectedIds.length && (data.filter(({ id }) => selectedIds.includes(id)).length === data.filter((v) => !disabled(v)).length);
  */

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
      {
        data.map((record) => React.cloneElement(React.Children.only(children), { record }))
      }
    </Box>
  );
}

DataTable.propTypes = {
  data: PropTypes.any,
  children: PropTypes.any,
  /* sort: PropTypes.exact({
    order: PropTypes.string,
    orderBy: PropTypes.string
  }),
  setSort: PropTypes.func,
  onRowClick: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  setSelected: PropTypes.func,
  headCells: PropTypes.any,
  disabled: PropTypes.func,
  checkboxSelection: PropTypes.bool
  */
};

export default DataTable;
