import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Card, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useUserInfo from '../../hooks/useUserInfo';
import GqlMutate from '../../components/GqlMutate';
import ProjectForm from './ProjectForm';
import Fetch from '../../components/GqlFetch';
import ProjectSentences from './ProjectSentences';
import ProjectLanguages from './ProjectLanguages';
import ProjectTranslation from './ProjectTranslation';
import ProjectMembers from './ProjectMembers';
import ProjectClients from './ProjectClients';

const ProjectCreateMutation = gql`
  mutation insert_projects($object : projects_insert_input!) {
    insert_projects_one(object : $object) {
      id
      name
    }
  }
`;

const ProjectFetch = gql`
  query get_projects($id : Int!) {
    project : projects_by_pk(id: $id) {
      sentences_aggregate {
        aggregate {
          count
        }
      }
      owner_id
      projects_members {
        id
        user {
          id
          email
          last_name
          first_name
        }
      }
      projects_clients {
        id
        client {
          id
          password
          title
          username
        }
      }
      id
      name
      source_language_id
      sentences {
        translations {
          id
          text
          language_id     
        }
        description
        text
        created_at
        id   
      }
      language  {
        code
        id
        name
      }
      projects_languages {
        id
        language_id
        language {
          translations_aggregate(where : {sentence : {project_id : {_eq : $id}}}) {
            aggregate {
              count
            }
          }
          id
          code
          name
        }
      }
    }
    languages {
      id
      code
      name
    }
  }
`;

const ProjectDataFetch = gql`
  query get_projects {
    languages {
      id
      code
      name
    }
  }
`;

/* const ProjectUpdateMutation = gql`
  mutation update_projects($id : Int!, $object : projects_set_input) {
    update_projects_by_pk(pk_columns : {id : $id}, _set : $object) {
        id
        name
      }
    }
`;
*/

const Loader = ({ loading, children, ...rest }) => (loading ? null : React.cloneElement(children, { loading, ...rest }));

Loader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node
};

const Create = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <Box sx={{ p: 3 }}>
        <Fetch query={ProjectDataFetch}>
          <Loader>
            <GqlMutate mutation={ProjectCreateMutation} onCompleted={() => navigate('../')}>
              <Loader>
                <ProjectForm />
              </Loader>
            </GqlMutate>
          </Loader>
        </Fetch>
      </Box>
    </Card>
  );
};

const tabs = [
  { label: 'Languages', value: 'languages', psermission: 110 },
  { label: 'Sentences', value: 'sentences', psermission: 110 },
  { label: 'Translations', value: 'translation', psermission: 100 },
  { label: 'Members', value: 'members', psermission: 110 },
  { label: 'Clients', value: 'clients', psermission: 125 }
  // { label: 'Token', value: 'Token' }
];

const Update = () => {
  const { user } = useUserInfo();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState('languages');
  const { t } = useTranslation();

  if (!user.id) {
    return null;
  }

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Box>
      {user.permission_id <= 100 ? null
        : (
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.filter((tab) => user.permission_id >= tab.psermission).map((tab) => (
              <Tab
                key={tab.value}
                label={t(tab.label)}
                value={tab.value}
              />
            ))}
          </Tabs>
        )}
      <Fetch query={ProjectFetch} id={id}>
        <Loader>
          <Main selected={user.permission_id <= 100 ? 'translation' : currentTab} />
        </Loader>
      </Fetch>
    </Box>
  );
};

const Main = ({ selected, ...rest }) => (
  <Box mt={2}>
    {selected === 'languages' && <ProjectLanguages {...rest} />}
    {selected === 'sentences' && <ProjectSentences {...rest} />}
    {selected === 'translation' && <ProjectTranslation {...rest} />}
    {selected === 'members' && <ProjectMembers {...rest} />}
    {selected === 'clients' && <ProjectClients {...rest} />}

  </Box>
);

Main.propTypes = {
  selected: PropTypes.string
};

export {
  Create,
  Update
};
