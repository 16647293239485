import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import gql from 'graphql-tag';
import { useNavigate } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import List from '../../components/GqlList/GqlList';
import IconButtonMutation from '../../components/IconButtonMutation';
import DataTable from '../../components/GqlList/DataTable';

const headCells = [
  { field: 'title', headerName: 'title', order: 'title', searchable: true },
  { field: 'username', headerName: 'client id', order: 'client_id' },
  { field: 'password', headerName: 'password', order: 'password' },
];

const query = gql`
  query Clients($offset: Int, $limit: Int, $order_by: [clients_order_by!], $where: clients_bool_exp) {
    list : clients(
      offset: $offset, 
      limit: $limit, 
      order_by: $order_by, 
      where: $where
    ) {
      id
      title
      password
      username
    }
    aggregate : clients_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const ClientsDeleteMutation = gql`
  mutation delete_client($ids : [uuid!]) {
    delete_client(ids: $ids) {
      affected_rows
    }
  }`;

const AddClient = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Add')}>
      <IconButton aria-label="add" onClick={() => navigate('./create')}>
        <Add />
      </IconButton>
    </Tooltip>
  );
};

const DeleteClients = ({ ids = [], onCompleted }) => {
  const { t } = useTranslation();
  return (
    <IconButtonMutation title={t('Delete')} mutation={ClientsDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
      <DeleteIcon />
    </IconButtonMutation>
  );
};

DeleteClients.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const ClientsList = () => (
  <>
    <List name="tableC" headCells={headCells} query={query} actions={[<AddClient />]} onSelectActionComponent={[<DeleteClients />]}>
      <DataTable />
    </List>
  </>
);

export default ClientsList;
