import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import MaBreadcrumbs from '../../components/MaBreadcrumbs';
import ClientsList from './ClientsList';
import { CreateClient, UpdateClient } from './Client';

const paths = [
  {
    title: 'Home',
    to: '/app/dashboard'
  },
  {
    title: 'Clients'
  }
];

const Clients = () => (
  <>
    <Helmet>
      <title>Clients | Master</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
      <Container maxWidth="false">
        <MaBreadcrumbs title="Clients" paths={paths} />
        <Box sx={{ mt: 3 }}>
          <Routes>
            <Route path="/create" element={<CreateClient />} />
            <Route path="/update/:id" element={<UpdateClient />} />
            <Route path="/" element={<ClientsList />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Box>
      </Container>
    </Box>
  </>
);

export default Clients;
