import React, { useEffect, useState, useRef } from 'react';
import { Chip, Popover, Paper, TextField, MenuItem, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const BooleanFilter = (props) => {
  const { name, onDelete, label, setFilterValue, value: _value } = props;
  const divRef = useRef();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const [operator] = useState('_is_null');

  useEffect(() => {
    setOpen(true);
  }, []);
  const options = [{ value: '_is_null', label: 'is' }];

  const labelToShow = _value ? `${label} ${options.find((v) => v.value === operator).label} ${value}` : label;
  const c = _value ? 'default' : 'secondary';
  const color = open ? 'primary' : c;

  return (
    <div>
      <Chip onClick={() => setOpen(true)} label={labelToShow} aria-describedby={name} onDelete={() => onDelete(name)} style={{ marginLeft: '8px' }} ref={divRef} color={color} />
      <Popover
        onClose={() => setOpen(false)}
        id={name}
        open={open}
        anchorEl={divRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        modifiers={{ arrow: { enabled: true } }}
      >
        <Paper style={{ margin: '2px', padding: '2px', display: 'flex' }}>
          <TextField
            size="small"
            variant="outlined"
            margin="dense"
            style={{ marginRight: '5px' }}
            select
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <MenuItem aria-label="None" value="" />
            <MenuItem value={false}> false</MenuItem>
            <MenuItem value>true</MenuItem>
          </TextField>

          <Button onClick={() => { setOpen(false); setFilterValue(name, { [operator]: !value }); }}>Save</Button>
        </Paper>
      </Popover>
    </div>
  );
};

BooleanFilter.propTypes = {
  onDelete: PropTypes.func,
  label: PropTypes.string,
  setFilterValue: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any
};

export default BooleanFilter;
