import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import randString from 'mout/random/randString';
import FiltersButton from '../Filters/FiltersButton';
import Filters from '../Filters/Filters';
import SearchInput from '../DebouncedSearchInput';

const useStyles = makeStyles((theme) => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '64px',
    justifyContent: 'space-between'
  },
  actions: {
    display: 'flex',
  },
  title: {
    paddingLeft: theme.spacing(2),
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  highlight:
    theme.palette.mode === 'dark'
      ? {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: theme.shape.borderRadius
      }
      : {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.55),
        borderRadius: theme.shape.borderRadius
      },
}));

const Actions = ({ actions, ids, onCompleted }) => (
  <div style={{ display: 'flex' }}>
    {actions.map((component) => React.cloneElement(component, { ids, key: randString(5), onCompleted }))}
  </div>
);

Actions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onCompleted: PropTypes.func,
  ids: PropTypes.arrayOf(PropTypes.number)
};

function TableHeaders({ refetch = Function.prototype, search, selectedIds, setSelected = Function.prototype, searchableFields = false, onSearch, filtersList = [], filters = {}, setFilters, actions = [], onSelectActionComponent = [] }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const setFilter = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };

  const setFilterFilterButton = (filter) => {
    setFilters({ ...filters, [filter]: Object.keys(filters).includes(filter) ? undefined : null });
  };

  return (
    <>
      {selectedIds.length > 0 ? (
        <div className={clsx(classes.row, classes.highlight)}>
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {`${selectedIds.length} ${t('selected')}`}
          </Typography>
          <div className={classes.actions}>
            <Actions actions={onSelectActionComponent} ids={selectedIds} onCompleted={() => { setSelected([]); refetch(); }} />
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.row}>
            {searchableFields ? (
              <SearchInput
                onChange={onSearch}
                value={search}
                className={classes.searchInput}
                placeholder={t('Search')}
              />
            ) : <div />}
            <div className={classes.actions}>
              <Actions actions={actions} />
              <FiltersButton
                filtersList={filtersList}
                handleChange={(filter) => setFilterFilterButton(filter, null)}
                filters={Object.keys(filters)}
              />
            </div>
          </div>
          <div style={{ marginBottom: '8px', display: 'flex' }}>
            {Object.keys(filters).map((filter) => (
              <Filters key={filter} {...filtersList.find(((f) => f.name === filter))} value={filters[filter]} setFilterValue={setFilter} onDelete={(f) => setFilter(f, undefined)} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

TableHeaders.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  filtersList: PropTypes.arrayOf(PropTypes.any),
  filters: PropTypes.any,
  setFilters: PropTypes.func,
  setSelected: PropTypes.func,
  searchableFields: PropTypes.bool,
  search: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  onSelectActionComponent: PropTypes.arrayOf(PropTypes.object),
  refetch: PropTypes.func,
  onSearch: PropTypes.func
};

export default TableHeaders;
