import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import gql from 'graphql-tag';
import { useNavigate } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import List from '../../components/GqlList/GqlList';
import IconButtonMutation from '../../components/IconButtonMutation';
import DataTable from '../../components/GqlList/DataTable';

const headCells = [
  { field: 'first_name', headerName: 'First Name', order: 'first_name', searchable: true },
  { field: 'last_name', headerName: 'Last Name', order: 'last_name', searchable: true },
  { field: 'email', headerName: 'Email', order: 'email', searchable: true },
  { field: 'permission.level', headerName: 'Role', order: 'permission.level', searchable: true }
];

const query = gql`
  query Users($offset: Int, $limit: Int, $order_by: [users_order_by!], $where: users_bool_exp) {
    list : users(
      offset: $offset, 
      limit: $limit, 
      order_by: $order_by, 
      where: $where
    ) {
      id
      first_name
      last_name
      email
      permission {
        id
        level
      }
    }
    aggregate : users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const UsersDeleteMutation = gql`
  mutation delete_user($ids : [uuid!]) {
    delete_user(ids: $ids) {
      affected_rows
    }
  }`;

const AddUser = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Add')}>
      <IconButton aria-label="add" onClick={() => navigate('./create')}>
        <Add />
      </IconButton>
    </Tooltip>
  );
};

const DeleteUsers = ({ ids = [], onCompleted }) => {
  const { t } = useTranslation();
  return (
    <IconButtonMutation title={t('Delete')} mutation={UsersDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
      <DeleteIcon />
    </IconButtonMutation>
  );
};

DeleteUsers.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const UsersList = () => (
  <>
    <List name="table0" headCells={headCells} query={query} actions={[<AddUser />]} onSelectActionComponent={[<DeleteUsers />]}>
      <DataTable />
    </List>
  </>
);

export default UsersList;
