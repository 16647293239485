import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { TextField, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { omit } from 'mout/object';
import { useTranslation } from 'react-i18next';

const ClientSchema = () => yup.object().shape({
  title: yup.string().max(128, 'Too Long!').required('First Name is Required')
});

const defaultValues = {
  title: '',
};

const ClientForm = ({ data = { client: {}, permissions: [] }, mutate = Function.prototype, loading = false }) => {
  const initialValues = { ...defaultValues, ...omit(data.client, '__typename') };
  const { t } = useTranslation();

  return (
    <Formik
      validationSchema={ClientSchema()}
      initialValues={initialValues}
      onSubmit={(_object) => {
        const object = { ..._object };
        mutate({ variables: { object, id: object.id } });
      }}
    >
      {({ handleSubmit, errors, touched, validateForm }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Field name="title" as={TextField} label={t('title')} fullWidth margin="dense" variant="outlined" error={(errors.title && touched.title)} helperText={errors.title} />
            </Grid>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '20px' }}
            onClick={() => validateForm() && handleSubmit()}
            disabled={loading}
          >
            {t('Save details')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

ClientForm.propTypes = {
  data: PropTypes.object,
  mutate: PropTypes.func,
  loading: PropTypes.bool,
};

export default ClientForm;
