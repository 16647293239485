import React, {
  useState, createContext
} from 'react';

import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';

const authContext = createContext();

let _token = null;
let _roles = null;
export function getAuth() {
  return { token: _token, roles: _roles };
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [token, setToken] = useState(null);
  const keycloak = new Keycloak({
    realm: window._env_.APP_KEYCLOAK_REALM,
    url: window._env_.APP_KEYCLOAK_URL,
    clientId: window._env_.APP_KEYCLOAK_CLIENT_ID,
  });

  const logIn = () => {
    keycloak.init({
      onLoad: 'check-sso',
      pkceMethod: 'S256',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
    }).then((authenticated) => {
      if (!authenticated) {
        keycloak.login();
      } else {
        _token = keycloak.token;
        _roles = keycloak.realmAccess.roles;
        setToken({ keycloak_id: keycloak.subject, token: keycloak.token, roles: keycloak.realmAccess.roles, logOut: keycloak.logout.bind(keycloak) });
        setInterval(() => {
          keycloak.updateToken(30).then((refreshed) => {
            if (refreshed) {
              console.log('Token was successfully refreshed');
              _token = keycloak.token;
              _roles = keycloak.realmAccess.roles;
              setToken({ keycloak_id: keycloak.subject, token: keycloak.token, roles: keycloak.realmAccess.roles, logOut: keycloak.logout.bind(keycloak) });
            } else {
              console.log('Token is still valid');
            }
          }).catch(() => {
            console.log('Failed to refresh the token, or the session has expired');
            try {
              keycloak.logout();
            } catch (err) { console.log(err); }
          });
        }, 1000 * 25);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  // Return the user object and auth methods
  return {
    ...token,
    logIn,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default authContext;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
