import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout/index';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';
import Users from './pages/Users/index';
import Clients from './pages/Clients/index';
import Projects from './pages/Projects/index';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Settings from './pages/Settings';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'users/*', element: <Users /> },
      { path: 'clients/*', element: <Clients /> },
      { path: 'projects/*', element: <Projects /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
