import React from 'react';
import { Breadcrumbs, Typography, Link, Grid } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ChevronRightIcon from '../icons/ChevronRight';

const MaBreadcrumb = ({ title, paths = [] }) => {
  const { t } = useTranslation();
  const v = useLocation();
  console.log(v, title, paths);

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {t(title)}
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRightIcon fontSize="small" />}
          sx={{ mt: 1 }}
        >
          {paths.map(({ title: tt, to }) => (
            to ? (
              <Link
                color="textPrimary"
                component={RouterLink}
                to={to}
                variant="subtitle2"
              >
                {t(tt)}
              </Link>
            ) : (
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                {t(tt)}
              </Typography>
            )
          ))}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

MaBreadcrumb.propTypes = {
  title: PropTypes.bool,
  paths: PropTypes.arrayOf(PropTypes.any)
};

export default MaBreadcrumb;
