import { Box, Avatar, Card, Grid, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BriefcaseIcon from '../../icons/Briefcase';
import LanguagesIcon from '../../icons/Languages';
import PencilAlt from '../../icons/PencilAlt';

const Dashboard = ({ data }) => {
  const { t } = useTranslation();
  const languagesCount = data.languages_aggregate.aggregate.count;
  const projectsCount = data.projects_aggregate.aggregate.count;
  const sentencesCount = data.sentences_aggregate.aggregate.count;
  const translationsCount = data.translations_aggregate.aggregate.count;

  const toTranslate = data.projects.reduce(
    (accumulator, { projects_languages_aggregate: a, sentences_aggregate: b }) => accumulator + a.aggregate.count * b.aggregate.count,
    0
  );
  const progress = toTranslate === 0 ? 100 : parseInt((translationsCount / toTranslate) * 100, 10);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={3}
        >
          <Card
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="overline"
              >
                {t('TOTAL PROJECTS')}
              </Typography>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  color="textPrimary"
                  sx={{ mr: 1 }}
                  variant="h5"
                >
                  {projectsCount}
                </Typography>
              </Box>
            </Box>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                height: 48,
                width: 48
              }}
            >
              <BriefcaseIcon />
            </Avatar>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}
        >
          <Card
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="overline"
              >
                {t('TOTAL SENTENCES')}
              </Typography>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  color="textPrimary"
                  sx={{ mr: 1 }}
                  variant="h5"
                >
                  {sentencesCount}
                </Typography>
              </Box>
            </Box>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                height: 48,
                width: 48
              }}
            >
              <PencilAlt />
            </Avatar>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}
        >
          <Card
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="overline"
              >
                {t('TOTAL LANGUAGES')}
              </Typography>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  color="textPrimary"
                  sx={{ mr: 1 }}
                  variant="h5"
                >
                  {languagesCount}
                </Typography>
              </Box>
            </Box>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                height: 48,
                width: 48
              }}
            >
              <LanguagesIcon />
            </Avatar>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={3}
        >
          <Card sx={{ p: 3 }}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="overline"
            >
              {t('TRANSLATION PROGRESS')}
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              <Typography
                color="textPrimary"
                sx={{ mr: 1 }}
                variant="h5"
              >
                {`${progress}%`}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <LinearProgress
                  color="primary"
                  value={progress}
                  variant="determinate"
                />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

Dashboard.propTypes = {
  data: PropTypes.any
};

export default Dashboard;
