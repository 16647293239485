import React, { useState } from 'react';
import { Tooltip, IconButton, Menu, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';

const Filters = ({ filters = [], filtersList = [], handleChange = Function.prototype }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    filtersList.length ? (
      <div>
        <Tooltip title="Filter Table">
          <IconButton aria-controls="simple-menu" aria-label="view columns" onClick={handleClick}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {filtersList.map((filter) => (
            <MenuItem button key={filter.name} value={filter.name} onClick={() => { handleChange(filter.name); handleClose(); }}>
              <Checkbox checked={filters.indexOf(filter.name) > -1} />
              <ListItemText primary={filter.label} />
            </MenuItem>
          ))}
        </Menu>
      </div>
    ) : null
  );
};

Filters.propTypes = {
  handleChange: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.any),
  filtersList: PropTypes.arrayOf(PropTypes.any)
};

export default Filters;
