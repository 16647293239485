import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import Fetch from '../../components/GqlFetch';

import Dashboard from './Dashboard';
import ProjectList from './ProjectList';

const Loader = ({ loading, children, ...rest }) => (loading ? null : React.cloneElement(children, { loading, ...rest }));

Loader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node
};

const Query = gql`
  query get_data {
    projects_aggregate {
      aggregate {
        count
      }
    }
    sentences_aggregate {
      aggregate {
        count
      }
    }
    
    languages_aggregate {
      aggregate {
        count
      }
    }
    translations_aggregate {
      aggregate {
        count
      }
    }
    
    projects {
      id
      projects_languages_aggregate {
        aggregate {
          count
        }
      }
      sentences_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const Index = () => (
  <>
    <Helmet>
      <title>Dashboard | Translation</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Box
          sx={{
            backgroundColor: 'background.default',
            p: 3
          }}
        >
          <Fetch query={Query}>
            <Loader>
              <Dashboard />
            </Loader>
          </Fetch>
          <ProjectList />
        </Box>
      </Container>
    </Box>
  </>
);

export default Index;
