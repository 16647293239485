import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import MaBreadcrumbs from '../../components/MaBreadcrumbs';
import ProjectsList from './ProjectsList';
import { Create, Update } from './Project';

const paths = [
  {
    title: 'Home',
    to: '/app/dashboard'
  },
  {
    title: 'Projects'
  }
];

const Projects = () => (
  <>
    <Helmet>
      <title>Projects | Master</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
      <Container maxWidth="false">
        <MaBreadcrumbs title="Projects" paths={paths} />
        <Box sx={{ mt: 3 }}>
          <Routes>
            <Route path="/create" element={<Create />} />
            <Route path="/update/:id" element={<Update />} />
            <Route path="/" element={<ProjectsList />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </Box>
      </Container>
    </Box>
  </>
);

export default Projects;
