import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import useAuth from './useAuth';

const UserQuery = gql`
  query GetUser($keycloakId : uuid){
    users(where : {keycloak_id : {_eq : $keycloakId}}) {
      first_name
      last_name
      email
      id
      permission_id
    }
  }
`;

const useUserInfo = () => {
  const { keycloak_id: keycloakId } = useAuth();
  const [getUser, { loading, data }] = useLazyQuery(UserQuery);
  const user = (data && data.users) ? data.users[0] : {};
  useMemo(() => {
    if (keycloakId && !user.id) {
      getUser({ variables: { keycloakId } });
    }
  }, [keycloakId]);

  const values = useMemo(() => ({ loading, user }), [loading, user.id]);
  return values;
};

export default useUserInfo;
