import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';

import { AuthProvider } from './contexts/AuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import ApolloProviderApp from './components/ApolloProviderApp';
import * as serviceWorker from './serviceWorker';

import App from './App';
import LoadingScreen from './components/LoadingScreen';

ReactDOM.render((
  <BrowserRouter>
    <SettingsProvider>
      <AuthProvider>
        <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} hideIconVariant={false}>
          <ApolloProviderApp>
            <Suspense fallback={<LoadingScreen />}>
              <App />
            </Suspense>
          </ApolloProviderApp>
        </SnackbarProvider>
      </AuthProvider>
    </SettingsProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
