import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, makeStyles, MenuItem, Select, Tooltip, CircularProgress } from '@material-ui/core';
import { Add, Delete, CloudDownload } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
// import { useNavigate } from 'react-router';
import moment from 'moment';

import DataTable from '../../components/GqlList/DataTable';
import TableHeaders from '../../components/GqlList/TableHeaders';
import IconButtonMutation from '../../components/IconButtonMutation';
import ProgressTranslation from '../../components/GqlList/fields/Progress';

const DownloadTranslationQuery = gql`
  query Download_translation($languageId: Int!, $projectId: Int!){
    projects_by_pk(id: $projectId){
      id
      sentences {
        text
        id
        translations(where : {language_id : {_eq : $languageId}}){
          id
          text
          language_id
        }
      }
    }
  }
`;

const useStylesCsvButton = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: 5,
    left: 4,
    zIndex: 1,
  },
}));

const DownloadTranslations = ({ record }) => {
  const classes = useStylesCsvButton();

  const { projectId, languageId } = record;
  const [download, { loading }] = useLazyQuery(DownloadTranslationQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const result = {};

      data.projects_by_pk.sentences.forEach((s) => {
        const { text, translations } = s;
        result[text] = (translations[0] || {}).text;
      });

      const blob = new Blob([JSON.stringify(result, null, 2)], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = `${record.name}-${moment().format('DD-MM-YYYY-hh:mm:ss')}.json`;
      link.href = url;
      link.click();
    },
    onError: (err) => { console.log(err); }
  });

  return (
    <div>
      <Tooltip title="Download CSV" aria-label="Download CSV">
        <div className={classes.wrapper}>
          <IconButton onClick={() => download({ variables: { projectId, languageId } })} disabled={loading}>
            <CloudDownload />
            {loading && <CircularProgress className={classes.progress} />}
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

DownloadTranslations.propTypes = {
  record: PropTypes.any
};

const headCells = [
  { field: 'name', headerName: 'Language' },
  { field: 'code', headerName: 'Code' },
  { field: 'progression', headerName: 'Progression', component: <ProgressTranslation label="Translations done" /> },
  { field: 'download', headerName: 'Download', component: <DownloadTranslations /> },

];

const AddLanguages = ({ languages = [], onClick = Function.prototype }) => {
  const [languagesId, setLanguage] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div>
      <Tooltip title={t('Add')}>
        <IconButton aria-label="add" onClick={() => { setOpen(true); }}>
          <Add />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText>
            {`${t('Choose language from the list')} :`}
          </DialogContentText>
          <Select
            autoFocus
            fullWidth
            value={languagesId || 'none'}
            onChange={handleLanguageChange}
          >
            <MenuItem value="none" key="none" disabled>{t('Select Language')}</MenuItem>
            {languages.map((language) => <MenuItem value={language.id} key={language.id}>{t(language.name)}</MenuItem>)}
          </Select>

        </DialogContent>
        <DialogActions>
          <Button disabled={!languagesId} onClick={() => onClick(languagesId, handleClose)} color="primary">
            {t('Ok')}
          </Button>
          <Button onClick={handleClose} color="secondary">
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddLanguages.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.any),
  onClick: PropTypes.func
};

const AddLanguageMutation = gql`
  mutation Add_Language($languageId : Int!, $projectId : Int!) {
    insert_projects_languages_one(object : {
      language_id : $languageId,
      project_id : $projectId
    }){
      id
      language_id
      project_id
    }
  }
`;

const ProjectLanguegeDeleteMutation = gql`
mutation Delete_language($ids : [Int!]) {
  delete_projects_languages(where : {id : {_in : $ids}}){
    affected_rows
  }
}
`;

const DeleteLanguage = ({ ids = [], onCompleted }) => {
  const { t } = useTranslation();

  return (
    <IconButtonMutation title={t('Delete')} mutation={ProjectLanguegeDeleteMutation} variables={{ ids }} onCompleted={onCompleted}>
      <Delete />
    </IconButtonMutation>
  );
};

DeleteLanguage.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  onCompleted: PropTypes.func
};

const ProjectLanguages = ({ loading, data = {}, refetch }) => {
  const { languages, project } = data;
  const [selectedIds, setSelected] = useState([]);
  const [mutate, { loading: loadingMutation }] = useMutation(AddLanguageMutation);
  const handelAddLanguage = (languageId) => {
    mutate({ variables: { languageId, projectId: project.id } }).then(() => refetch());
  };

  return (
    <div>
      <TableHeaders
        selectedIds={selectedIds}
        // filtersList={filtersList}
        // filters={filters}
        // setFilters={setFilters}
        actions={[<AddLanguages languages={languages.filter((l) => !project.projects_languages.map((v) => v.language.id).includes(l.id))} onClick={handelAddLanguage} loading={loadingMutation} />]}
        setSelected={setSelected}
        onSelectActionComponent={[<DeleteLanguage />]}
        refetch={() => refetch()}
      />
      <DataTable
        // onRowClick={onRowClick}
        disabled={(row) => row.languageId === project.source_language_id}
        headCells={headCells}
        data={project.projects_languages.map(({ language, id }) => ({ languageId: language.id, projectId: project.id, ...language, id, progression: project.sentences_aggregate.aggregate.count ? (language.translations_aggregate.aggregate.count / project.sentences_aggregate.aggregate.count) : 0 }))}
        selectedIds={selectedIds}
        setSelected={setSelected}
        checkboxSelection
        loading={loading}
      />
    </div>
  );
};

ProjectLanguages.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  refetch: PropTypes.func
};

export default ProjectLanguages;
