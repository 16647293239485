import React from 'react';
import { useMutation } from '@apollo/react-hooks';

const Create = ({ mutation, onCompleted = Function.prototype, children, ...rest }) => {
  const [mutate, { loading }] = useMutation(mutation, {
    onCompleted,
  });

  return React.cloneElement(children, { mutate: (...args) => mutate(...args).catch((err) => console.log(err)), ...rest, loading });
};

export default Create;
