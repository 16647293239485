import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Label from '../../components/Label';
// import { useMutation } from '@apollo/react-hooks';
// import { useNavigate } from 'react-router';

// <TextareaAutosize aria-label="minimum height" minRows={4} style={{ width: '100%', maxWidth: '100%' }} />

const insertTranslationMutation = gql`
  mutation Insert_translation($text: String, $sentenceId: uuid!, $languageId: Int!){
    insert_translations_one(object: {language_id : $languageId, text: $text, sentence_id: $sentenceId}){
      id
      text
      sentence_id
      language_id
    }
  }
`;

const updateTranslationMutation = gql`
  mutation Update_translation($text: String, $sentenceId: uuid!, $languageId: Int!
    , $id : uuid!){
    update_translations_by_pk(
      pk_columns : {id : $id}, 
      _set : {
        text: $text, sentence_id: $sentenceId, language_id: $languageId
      }
    ){
      id
      text
      sentence_id
      language_id
    }
  }
`;

const Translation = ({ translation = '', insertTranslation, disabled }) => {
  const [translationData, setTranslation] = useState(translation);
  useEffect(() => {
    setTranslation(translation);
  }, [translation]);
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography
          color="textSecondary"
          sx={{
            mb: 2,
          }}
          variant="subtitle2"
        >
          {t('Translation')}
        </Typography>
        <TextField
          multiline
          rows={5}
          variant="outlined"
          value={translationData}
          fullWidth
          onChange={(event) => setTranslation(event.target.value)}
        />
      </CardContent>
      <Box sx={{ p: 2, float: 'right' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => insertTranslation(translationData)}
          disabled={disabled}
        >
          {t('Save and Next')}
        </Button>
      </Box>
    </Card>
  );
};

Translation.propTypes = {
  translation: PropTypes.string,
  disabled: PropTypes.bool,
  insertTranslation: PropTypes.func
};

const ProjectTranslation = ({ data = {}, refetch }) => {
  const { project } = data;
  const [selectedId, setSelected] = useState((project.sentences[0] || {}).id);
  const { t } = useTranslation();
  const [languageId, setLanguageId] = useState((project.projects_languages[0] || {}).id);
  const [insertTranslationF] = useMutation(insertTranslationMutation);
  const [updateTranslation] = useMutation(updateTranslationMutation);

  const selectedSentence = project.sentences.find((s) => (s.id === selectedId));
  const trueLanguageId = project.projects_languages.find((v) => (v.id === languageId)).language_id;
  const sentenceTranslation = selectedSentence ? selectedSentence.translations.find((s) => (s.language_id === trueLanguageId)) || {} : {};

  const insertTranslation = (text) => {
    const translationId = sentenceTranslation.id;
    const sentenceId = selectedSentence.id;
    if (!translationId) {
      insertTranslationF({ variables: { text, sentenceId, languageId: trueLanguageId } }).then(refetch);
    } else {
      updateTranslation({ variables: { text, sentenceId, languageId: trueLanguageId, id: translationId } }).then(refetch);
    }
  };

  return (
    <div>
      <TextField
        select
        size="small"
        label={t('Language')}
        variant="outlined"
        value={languageId}
        onChange={(event) => setLanguageId(event.target.value)}
        sx={{ mb: 2, minWidth: 200, bgcolor: 'background.paper' }}
      >
        {project.projects_languages.map(({ language, id }) => ({ ...language, id })).map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {t(option.name)}
          </MenuItem>
        ))}
      </TextField>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={5}
          xs={12}
        >
          <Card>
            <CardHeader title={t('Sentences')} />
            <List disablePadding style={{ overflow: 'auto', maxHeight: '500px' }}>
              {
                project.sentences.map((sentence, index) => (
                  <Box key={sentence.id}>
                    <ListItem divider={index + 1 < project.sentences.length} button onClick={() => setSelected(sentence.id)} selected={selectedId === sentence.id}>
                      <ListItemText
                        disableTypography
                        primary={(
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {sentence.text}
                          </Typography>
                        )}
                        secondary={(
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              sx={{
                                overflow: 'hidden',
                                pr: 2,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                              variant="body2"
                            >
                              {sentence.text}
                            </Typography>
                          </Box>
                        )}
                      />
                      <ListItemSecondaryAction>
                        <Label color={(sentence.translations.find((s) => (s.language_id === trueLanguageId)) || {}).text ? 'success' : 'error'}>
                          {(sentence.translations.find((s) => (s.language_id === trueLanguageId)) || {}).text ? t('Translated') : t('Not translated')}
                        </Label>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Box>
                ))
                }
            </List>
          </Card>
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
        >
          <Translation translation={sentenceTranslation.text} insertTranslation={insertTranslation} disabled={!(selectedSentence && selectedSentence.id)} />
        </Grid>
      </Grid>
    </div>
  );
};

ProjectTranslation.propTypes = {
  data: PropTypes.any,
  refetch: PropTypes.func
};

export default ProjectTranslation;
